import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import BoxCell from '../cells/BoxCell';

const BoxCellsGrid = ({ options, loading, ...props }) => (
  <Grid container justifyContent="flex-start" {...props} spacing={2}>
    {options.map(({ header, title, subtitle, addedValue }) => (
      <BoxCell
        key={`${header}-${title}-${subtitle}-${addedValue}`}
        header={header}
        title={title}
        subtitle={subtitle}
        addedValue={addedValue}
        loading={loading}
      />
    ))}
  </Grid>
);

BoxCellsGrid.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      addedValue: PropTypes.string,
    }).isRequired,
  ).isRequired,
  loading: PropTypes.bool,
};

BoxCellsGrid.defaultProps = {
  loading: false,
};

export default BoxCellsGrid;
