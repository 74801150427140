import { IconButton } from '@mui/material';
import React from 'react';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PropTypes from 'prop-types';

const JoyRideHelp = ({ openJoyRide }) => (
  <IconButton
    onClick={openJoyRide}
    sx={{ bgcolor: 'warning.main' }}
    color="fingoWhite"
    size="small"
    disableFocusRipple
    disableRipple
    disableTouchRipple
  >
    <LightbulbIcon size="small" />
  </IconButton>
);

JoyRideHelp.propTypes = {
  openJoyRide: PropTypes.func.isRequired,
};
export default JoyRideHelp;
