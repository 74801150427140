import React from 'react';
import PropTypes from 'prop-types';
import { CancelOutlined, TaskAlt } from '@mui/icons-material';

const SuccessErrorIcon = ({ status }) => (status ? (
  <TaskAlt fontSize="medium" color="success" />
) : (
  <CancelOutlined fontSize="medium" color="error" />
));

SuccessErrorIcon.propTypes = {
  status: PropTypes.bool.isRequired,
};

export default SuccessErrorIcon;
