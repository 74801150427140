import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { FileUploadOutlined } from '@mui/icons-material';
import { IconButtonFilled } from '../buttons';

const CardFooter = ({ id, children, errorMessage, success, onActionClick }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      px: 3,
      py: 1,
      borderRadius: 4,
      minHeight: 60,
      backgroundColor: { sx: 'white', md: 'background.light' },
    }}
  >
    {success ? (
      children
    ) : (
      <Typography variant="error" color="error.dark">
        {errorMessage}
      </Typography>
    )}
    {onActionClick && (
      <IconButtonFilled
        id={`${id}-action-button`}
        icon={<FileUploadOutlined />}
        handleOnClick={onActionClick}
        borderRadius={2}
      />
    )}
  </Stack>
);

CardFooter.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  onActionClick: PropTypes.func,
};

CardFooter.defaultProps = {
  onActionClick: null,
};

export default CardFooter;
