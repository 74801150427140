import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, Tooltip } from '@mui/material';

const AlertsGrid = ({ children, alerts }) => (
  <Grid container direction="row">
    {alerts.map(({ value, label, color, tooltip, icon, onClick }) => (
      <Tooltip key={label} arrow title={value ? tooltip : ''} placement="top">
        <Avatar
          onClick={(e) => (onClick ? onClick(e) : null)}
          sx={{
            bgcolor: value ? color : '',
            width: 15,
            height: 15,
            margin: 0.1,
            fontSize: 8,
            cursor: onClick ? 'pointer' : 'default',
          }}
        >
          {icon}
        </Avatar>
      </Tooltip>
    ))}
    {children}
  </Grid>
);

AlertsGrid.propTypes = {
  children: PropTypes.node,
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      icon: PropTypes.element.isRequired,
    }).isRequired,
  ).isRequired,
};

AlertsGrid.defaultProps = {
  children: null,
};

export default AlertsGrid;
